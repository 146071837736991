import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const Featured = () => {
  return (
    <div className="featured flex flex-col flex-2 shadow-md p-10">
      <div className="top flex items-center justify-between text-gray-600">
        <h1 className="title text-lg font-semibold">Total Revenue</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom flex flex-col items-center justify-center gap-6 p-4">
        <div className="featuredChart w-24 h-24">
          <CircularProgressbar value={70} text={"70%"} strokeWidth={5} />
        </div>
        <p className="title font-semibold text-gray-600">Total sales made today</p>
        <p className="amount text-3xl">$4,208.00</p>
        <p className="desc text-sm text-gray-600">Previous transactions processing. Last payments may not be included.</p>
        <div className="summary w-full flex justify-between">
          <div className="item text-center">
            <div className="itemTitle text-gray-600 text-sm">Target</div>
            <div className="itemResult flex items-center mt-2 text-sm text-green-500">
              <KeyboardArrowDownIcon fontSize="small"/>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
          <div className="item text-center">
            <div className="itemTitle text-gray-600 text-sm">Last Week</div>
            <div className="itemResult flex items-center mt-2 text-sm text-green-500">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
          <div className="item text-center">
            <div className="itemTitle text-gray-600 text-sm">Last Month</div>
            <div className="itemResult flex items-center mt-2 text-sm text-green-500">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
