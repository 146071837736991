import React from 'react';

const Feedbacks = () => {
    return (
        <div className='border bg-white h-full flex flex-col justify-between'>
            <div className='w-full h-full self-stretch overflow-y-auto overflow-hidden scrollbar-thin'>
                <UserFeedback />
                <ReplyFeedback />
            </div>
            {/* Message input type area */}
            <div className='flex w-full gap-2 justify-around items-center p-2 bg-zinc-50'>
                <textarea
                    id="prompt-textarea"
                    placeholder="Message"
                    // value={inputMessage}
                    // onChange={(e) => setInputMessage(e.target.value)}
                    rows="1"
                    className='w-full h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                />
                <button
                    className='flex h-[40px] w-[40px] rounded-full bg-slate-200 justify-center items-center text-center text-[12px] border shadow-xl'
                // onClick={handleSendMessage}
                // disabled={inputMessage.trim() === ''}
                >
                    Send
                </button>
            </div>
        </div>
    )
};

const UserFeedback = () => {
    return (
        <div className='bg-indigo-100 p-2 w-full h-fit border flex flex-col flex-wrap'>
            <div className='flex rounded-xl border bg-white m-2 p-2 flex-col flex-wrap shadow-md'>
                <div className='flex w-full justify-between'>
                    <div className='text-[16px] font-semibold'>Feedback Title</div>
                    <div className='flex gap-2 flex-row-reverse items-center'>
                        <img src='' className='bg-zinc-300 animate-pulse object-cover h-[30px] w-[30px] rounded-full' />
                        <div className='text-[12px]'>Username</div>
                    </div>
                </div>
                <div className='text-[14px] font-medium py-1'>Ayo, nice work guys</div>
                <div className='w-full h-[1px] bg-zinc-300 mt-2'></div>
                <div className='flex gap-5 justify-between px-10'>
                    <div className='text-[12px]'>FeedbackID</div>
                    <div className='text-[12px]'>Timestamp</div>
                </div>
            </div>
        </div>
    )
};

const ReplyFeedback = () => {
    return (
        <div className='bg-green-100 p-2 w-full border flex flex-col flex-wrap'>
            <div className='text-[14px]'>Thanks for your feedback</div>
            <div className='flex gap-5 justify-between items-center'>
                <div className='text-[12px] font-bold text-slate-500'>iTrood Team</div>
                <div className='text-[10px] font-medium text-slate-500'>Feedback reply</div>
            </div>
        </div>
    )
};

export default Feedbacks;