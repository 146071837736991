import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const PushNotificationForm = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const sendPushNotification = async () => {
    try {
      const response = await axios.post(
        'https://4aca5e83-e5ff-47ee-a92f-72944b6d73ce.pushnotifications.pusher.com/publish_api/v1/instances/4aca5e83-e5ff-47ee-a92f-72944b6d73ce/publishes',
        {
          interests: ['hello'],
          web: {
            notification: {
              title: title,
              body: body
            }
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Authorization: Bearer 4FF347001A366CCC3EC19DADFFA51A2C5C1AD6FDAABC5B638CBC8808B4E9DF5B"
          }
        }
      );
      toast.success('Push notification sent:', response.data);
      console.log('Push notification sent:', response.data);
      // You can add any further handling here, like displaying a success message
    } catch (error) {
      toast.error('Error sending push notification:', error);
      console.error('Error sending push notification:', error);
      // Handle errors accordingly
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-3xl font-bold mb-4">Send Push Notification</h1>
      <div className="mb-4">
        <label htmlFor="title" className="block text-gray-700 font-bold mb-2">Title</label>
        <input type="text" id="title" className="w-full border border-gray-300 p-2" value={title} onChange={(e) => setTitle(e.target.value)} />
      </div>
      <div className="mb-4">
        <label htmlFor="body" className="block text-gray-700 font-bold mb-2">Body</label>
        <textarea id="body" className="w-full border border-gray-300 p-2" rows="4" value={body} onChange={(e) => setBody(e.target.value)}></textarea>
      </div>
      <div className="text-center">
        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={sendPushNotification}>Send</button>
      </div>
    </div>
  );
};

export default PushNotificationForm;