import React from 'react';
import { Link } from 'react-router-dom';

const Transactions = () => {
    return (
        <div className='flex flex-col w-full h-full items-center justify-center'>
            CominSoon
        </div>
    );
};

export default Transactions;
