import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

const Codes = () => {
  const [allCodes, setAllCodes] = useState([]);
  const [usedCodes, setUsedCodes] = useState([]);
  const [availableCodes, setAvailableCodes] = useState([]);
  const [groupedByValue, setGroupedByValue] = useState({});
  const [selectedCodes, setSelectedCodes] = useState(new Set());

  useEffect(() => {
    const fetchCodes = async () => {
      try {
        const codesCollection = collection(db, 'prePaidCodes');
        const querySnapshot = await getDocs(codesCollection);

        const allCodes = [];
        const usedCodes = [];
        const availableCodes = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          allCodes.push(data);

          if (data.used) {
            usedCodes.push(data);
          } else {
            availableCodes.push(data);
          }
        });

        setAllCodes(allCodes);
        setUsedCodes(usedCodes);
        setAvailableCodes(availableCodes);
        groupByValue(allCodes);
      } catch (error) {
        console.error('Error fetching codes:', error);
        toast.error('Failed to fetch codes');
      }
    };

    const groupByValue = (codes) => {
      const grouped = codes.reduce((acc, code) => {
        if (!acc[code.value]) {
          acc[code.value] = [];
        }
        acc[code.value].push(code);
        return acc;
      }, {});
      setGroupedByValue(grouped);
    };

    fetchCodes();
  }, []);

  const handleSelectCode = (codeId) => {
    setSelectedCodes((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(codeId)) {
        newSelected.delete(codeId);
      } else {
        newSelected.add(codeId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    setSelectedCodes(new Set(allCodes.map(code => code.id)));
  };

  const handleDeselectAll = () => {
    setSelectedCodes(new Set());
  };

  const handleAction = (action) => {
    if (selectedCodes.size === 0) {
      toast.error('No codes selected');
      return;
    }

    switch (action) {
      case 'print':
        printSelectedCodes();
        break;
      // Add more actions as needed
      default:
        break;
    }
  };

  const printSelectedCodes = () => {
    const selected = allCodes.filter(code => selectedCodes.has(code.id));
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print Codes</title>');
    printWindow.document.write('<style>body { font-family: Arial, sans-serif; margin: 20px; } .code { margin-bottom: 10px; padding: 10px; border: 1px solid #ddd; } .code h2 { margin: 0 0 10px; } </style>');
    printWindow.document.write('</head><body>');
    selected.forEach(code => {
      printWindow.document.write(`<div class="code"><h2>Code: ${code.code}</h2><p>Value: ${code.value}</p><p>Currency: ${code.currency}</p><p>Used: ${code.used ? 'Yes' : 'No'}</p></div>`);
    });
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
    toast.success('Selected codes are being printed');
  };

  const renderCodes = (codes) => (
    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {codes.map((code) => (
        <div key={code.id} className="p-4 border rounded shadow-sm bg-white">
          <input
            type="checkbox"
            checked={selectedCodes.has(code.id)}
            onChange={() => handleSelectCode(code.id)}
            className="mr-2"
          />
          <p className="font-semibold">Code: {code.code}</p>
          <p>Value: {code.value}</p>
          <p>Currency: {code.currency}</p>
          <p>Used: {code.used ? 'Yes' : 'No'}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div className="container mx-auto p-6">
      <header className="mb-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Prepaid Codes</h1>
        <div>
          <button onClick={handleSelectAll} className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Select All</button>
          <button onClick={handleDeselectAll} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Deselect All</button>
          <select onChange={(e) => handleAction(e.target.value)} className="ml-4 p-2 border rounded">
            <option value="">Actions</option>
            <option value="print">Print Selected</option>
            {/* Add more options as needed */}
          </select>
        </div>
      </header>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">All Codes Grouped by Value</h2>
        {Object.keys(groupedByValue).map((value) => (
          <div key={value} className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Value: {value}</h3>
            {renderCodes(groupedByValue[value])}
          </div>
        ))}
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Used Codes</h2>
        {renderCodes(usedCodes)}
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Available Codes</h2>
        {renderCodes(availableCodes)}
      </section>
    </div>
  );
};

export default Codes;