import React, { useContext, useState } from 'react';
import { collection, getDocs, query, where, serverTimestamp, doc, writeBatch } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { toast } from 'react-toastify';
import { DarkModeContext } from '../context/darkModeContext';
import { useNavigate } from 'react-router-dom';

const CreateCodes = () => {
  const userId = auth.currentUser.uid;
  const navigate = useNavigate();
  const { dispatch, darkMode } = useContext(DarkModeContext);
  const [valuePerCode, setValuePerCode] = useState('');
  const [numberOfCodes, setNumberOfCodes] = useState('');
  const [currency, setCurrency] = useState('TZS');
  const [generating, setGenerating] = useState(false);
  const [progress, setProgress] = useState(0);

  const generateUniqueCode = () => {
    const characters = '0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const generateTransactionID = () => {
    return `TRD${Math.floor(1000 + Math.random() * 9000)}`;
  };

  const handleGenerateCodes = async () => {
    if (!valuePerCode || !numberOfCodes || !currency) {
      toast.error('Please fill in all required fields');
      return;
    }

    const numCodesToGenerate = Math.min(parseInt(numberOfCodes), 100);
    setGenerating(true);
    setProgress(0);

    const codesCollection = collection(db, 'prePaidCodes');
    const batch = writeBatch(db);

    const generatedCodes = [];

    try {
      for (let i = 0; i < numCodesToGenerate; i++) {
        let generatedCode;
        let isUnique = false;

        while (!isUnique) {
          generatedCode = generateUniqueCode();
          const querySnapshot = await getDocs(query(codesCollection, where('code', '==', generatedCode)));
          if (querySnapshot.empty) {
            isUnique = true;
          }
        }

        const transactionID = generateTransactionID();
        const codeData = {
          code: generatedCode,
          content: '',
          created: serverTimestamp(),
          creditedTo: '',
          currency: currency,
          expire: '',
          expireDate: null,
          generatedBy: userId,
          id: generatedCode,
          symbal: currency === 'USD' ? '$' : '',
          transactionID: transactionID,
          used: false,
          user: '',
          value: valuePerCode,
        };

        const newDocRef = doc(codesCollection, generatedCode);
        batch.set(newDocRef, codeData);

        generatedCodes.push(codeData);
        setProgress((prevProgress) => prevProgress + 1);
      }

      await batch.commit();
      toast.success('Codes generated successfully');
      setValuePerCode('');
      setNumberOfCodes('');
      setCurrency('TZS');
      
      // Navigate to the new page and pass the generated codes
      navigate('/generated-codes', { state: { codes: generatedCodes } });
    } catch (error) {
      console.error('Error generating codes:', error);
      toast.error('Failed to generate codes');
    }

    setGenerating(false);
  };

  const totalValue = valuePerCode * numberOfCodes;

  return (
    <div className="flex flex-col gap-4">
      <input
        type="number"
        placeholder="Value per code"
        maxLength="3"
        value={valuePerCode}
        onChange={(e) => setValuePerCode(e.target.value)}
      />
      <input
        type="number"
        placeholder="Number of codes"
        maxLength="3"
        value={numberOfCodes}
        onChange={(e) => setNumberOfCodes(e.target.value)}
      />
      <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
        <option value="TZS">TZS</option>
        <option value="USD">USD</option>
      </select>
      <button
        onClick={handleGenerateCodes}
        disabled={generating || !valuePerCode || !numberOfCodes || !currency}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        {generating ? 'Generating...' : 'Generate'}
      </button>
      {generating && (
        <p className="text-xl">
          Generating: {progress} out of {Math.min(parseInt(numberOfCodes), 100)}
        </p>
      )}
      {totalValue > 0 && (
        <p className="text-xl">
          Total value: {currency} {totalValue.toFixed(2)}
        </p>
      )}
    </div>
  );
};

export default CreateCodes;