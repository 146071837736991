import React from 'react';
import { useLocation } from 'react-router-dom';

const GeneratedCodes = () => {
  const { state } = useLocation();
  const { codes } = state;

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print Codes</title>');
    printWindow.document.write('<style>body { font-family: Arial, sans-serif; margin: 20px; } .code { margin-bottom: 10px; padding: 10px; border: 1px solid #ddd; } .code h2 { margin: 0 0 10px; } </style>');
    printWindow.document.write('</head><body>');
    codes.forEach(code => {
      printWindow.document.write(`<div class="code"><h2>Code: ${code.code}</h2><p>Value: ${code.value}</p><p>Currency: ${code.currency}</p><p>Used: ${code.used ? 'Yes' : 'No'}</p></div>`);
    });
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="container mx-auto p-6">
      <header className="mb-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Generated Codes</h1>
        <button onClick={handlePrint} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          Print All Codes
        </button>
      </header>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {codes.map((code) => (
          <div key={code.id} className="p-4 border rounded shadow-sm bg-white">
            <p className="font-semibold">Code: {code.code}</p>
            <p>Value: {code.value}</p>
            <p>Currency: {code.currency}</p>
            <p>Used: {code.used ? 'Yes' : 'No'}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeneratedCodes;