import { useContext } from "react";
import { DarkModeContext } from "../context/darkModeContext";

export const userColumns = (darkMode) => [
  { 
    field: "id", 
    headerName: "ID", 
    width: 40, 
    headerClassName: `${darkMode ? "text-white" : "light-header"}`,
    renderCell: (params) => {
      return (
        <div className={`flex flex-shrink-0 justify-center items-center gap-1 ${darkMode ? "text-white" : "light-mode"}`}>
          {params.row.id}
        </div>
      );
    },
  },
  {
    field: "user",
    headerName: "User",
    width: 180,
    headerClassName: `${darkMode ? "text-white" : "light-header"}`,
    renderCell: (params) => {
      return (
        <div className={`flex flex-shrink-0 justify-center items-center gap-1 ${darkMode ? "text-white" : "light-mode"}`}>
          <img src={params.row.img} alt="avatar" className={`h-[40px] w-[40px] shrink-0 rounded-full ${darkMode ? "border" : "light-mode"}`} />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 180,
    headerClassName: `${darkMode ? "text-white" : "light-header"}`,
    renderCell: (params) => {
      return (
        <div className={`${darkMode ? "text-white" : "light-mode"}`}>
          {params.row.email}
        </div>
      );
    },
  },
  {
    field: "address",
    headerName: "Address",
    width: 130,
    headerClassName: `${darkMode ? "text-white" : "light-header"}`,
    renderCell: (params) => {
      return (
        <div className={`${darkMode ? "text-white" : "light-mode"}`}>
          {params.row.address}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
    headerClassName: `${darkMode ? "text-white" : "light-header"}`,
    renderCell: (params) => {
      return (
        <div className={`${darkMode ? "text-white" : "light-mode"} ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
  {
    field: "subscription",
    headerName: "Subscription",
    width: 110,
    headerClassName: `${darkMode ? "text-white" : "light-header"}`,
    renderCell: (params) => {
      return (
        <div className={`${darkMode ? "text-white" : "light-mode"}`}>
          {params.row.subscription}
        </div>
      );
    },
  },
];
