import { useContext, useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import PropagateLoader from 'react-spinners/PropagateLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import { logo2 } from "../assets";
import { doc, getDoc, getDocs, where, query, collection } from "firebase/firestore";
import { RiLoginCircleLine } from "react-icons/ri";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [accessLoading, setAccessLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState(null);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoginScreen, setShowLoginScreen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    // Retrieve the saved email from localStorage when the component mounts
    const savedEmail = localStorage.getItem('savedEmail');
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const handleGetUserData = async () => {
    try {
      setLoading(true);
      setError(false);

      const lowerCaseEmail = email.toLowerCase();
      const usersCollection = collection(db, "users");
      const userQuery = query(usersCollection, where("email", "==", lowerCaseEmail));
      const querySnapshot = await getDocs(userQuery);

      if (querySnapshot.empty) {
        throw new Error("User not found");
      }

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        setUserData(userData);
      });

      // Save the email to localStorage
      localStorage.setItem('savedEmail', email);

      setLoading(false);
    } catch (error) {
      console.error("Error retrieving user data:", error);

      if (error.message === "User not found") {
        setErrorMessage("User not found. Please check the entered email.");
      } else if (
        error.code === "unavailable" ||
        error.code === "cancelled" ||
        error.message.includes("Network Error")
      ) {
        setErrorMessage(
          "There was a problem connecting to the server. Please check your internet connection."
        );
      } else {
        setErrorMessage("An unexpected error occurred. Please try again later.");
      }

      setError(true);
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setLoggedIn(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      let errorMessage = "An error occurred. Please try again.";

      switch (error.code) {
        case "auth/user-not-found":
          errorMessage = "No user found with this email. Please check your email address or register for an account.";
          break;
        case "auth/wrong-password":
          errorMessage = "The password you entered is incorrect. Please try again.";
          break;
        case "auth/too-many-requests":
          errorMessage = "Too many unsuccessful login attempts. Please try again later.";
          break;
        case "auth/user-disabled":
          errorMessage = "Your account has been disabled. Please contact support for assistance.";
          break;
        case "auth/network-request-failed":
          errorMessage = "Network error. Please check your internet connection and try again.";
          break;
        default:
          errorMessage = "An error occurred. Please try again.";
          break;
      }

      setErrorMessage(errorMessage);
    }
  };

  const handleGetAccess = () => {
    setAccessLoading(true);

    setTimeout(() => {
      if (userData && (userData.status === 'admin')) {
        setShowLoginScreen(true);
      } else {
        setAccessDenied(true);
        setErrorMessage("Access Denied. User not authorized.");
      }
      setAccessLoading(false);
    }, 2000);
  };

  const handleSubmit = () => {
    if (email.trim() !== "") {
      handleGetUserData();
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img src={logo2} className="w-[200px] max-md:w-[150px] shrink-0 m-10" alt="iTrood logo" />
      {!userData && (
        <input
          type="email"
          placeholder="Email"
          required
          className="w-[350px] max-md:w-[200px] h-10 border border-gray-300 rounded-md px-2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      )}
      {!showLoginScreen && loading ? (
        <div className="w-[350px] max-md:w-[200px] h-10 pb-4 flex bg-zinc-100 rounded-md mt-2 justify-center items-center">
          <PropagateLoader color="#3693d6" />
        </div>
      ) : (
        !userData && !loading && (
          <>
            <button
              onClick={handleSubmit}
              disabled={email.trim() === ""}
              className={email.trim() === "" ? "bg-gray-300 cursor-not-allowed w-[350px] max-md:w-[200px] h-10 rounded-md mt-2" : "w-[350px] max-md:w-[200px] h-10 rounded-md mt-2 bg-blue-500 text-white"}
            >
              ENTER
            </button>
          </>
        ))}

      {error && !showLoginScreen && (<p className="text-red-500 text-sm text-center m-4">{errorMessage}</p>)}

      {userData && (
        <>
          <div className="flex flex-col p-2 justify-center items-center">
            <div className="flex border shadow-2xl rounded-lg min-h-[80px] w-[300px] justify-between items-center p-2 m-2">
              <div className='flex gap-3 h-full w-full items-center'>
                <img src={userData?.img} className="h-[50px] w-[50px] rounded-full object-cover" />
                <div>
                  <div className="font-semibold">{userData?.displayName}</div>
                  <div className="font-medium text-slate-400">{userData?.status}</div>
                </div>
              </div>
              {!showLoginScreen && !loading && accessLoading ? (
                <PuffLoader color="#3f83f8" />
              ) : (
                <>
                  <button onClick={handleGetAccess} title="GET ACCESS" className="cursor-pointer rounded-full flex justify-center items-center h-[40px] w-[40px] bg-zinc-100 hover:bg-blue-100 hover:shadow-md">
                    <RiLoginCircleLine className="h-[40px] w-[40px] hover:h-[35px] hover:w-[35px] hover:text-blue-500" />
                  </button>
                </>
              )}
            </div>
            {accessDenied && <span className="text-center text-red-500 text-sm mt-2">{errorMessage}</span>}
            <div>
              {showLoginScreen && (
                <div className="flex flex-col items-center justify-center">
                  <form onSubmit={handleLogin} className="flex flex-col items-center">
                    <div className="text-center font-bold text-slate-700 p-2">Sign In</div>
                    <input
                      type="password"
                      placeholder="Password"
                      required
                      className="w-[350px] max-md:w-[200px] h-10 border border-gray-300 rounded-md px-2 mt-2"
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    {loading ? (
                      <div className="w-[350px] max-md:w-[200px] h-10 pb-4 flex bg-zinc-100 rounded-md mt-2 justify-center items-center">
                        <PropagateLoader color="#3693d6" />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="w-[350px] max-md:w-[200px] h-10 bg-purple-500 text-white font-bold rounded-md mt-2 cursor-pointer"
                      >
                        Login
                      </button>
                    )}
                    {error && <span className="text-red-500 text-sm mt-2">{errorMessage}</span>}
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="text-center text-[14px] text-zinc-400 pt-4">Is this not your profile?</div>
          <Link to="/" className="text-center font-bold text-blue-500 pb-2 cursor-pointer">Go Back</Link>
        </>
      )}
    </div>
  );
};

export default Login;