import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

const UserSubscription = ({ userName, userEmail, userPhone, expiredSubscriptions, activeSubscriptions }) => {
    return (
        <div className="border rounded-lg p-4 mb-4 shadow-md w-[320px]">
            <h2 className="text-xl font-semibold mb-2">{userName}</h2>
            <p><strong>Email:</strong> {userEmail}</p>
            <p><strong>Phone:</strong> {userPhone}</p>
            <div>
                <h3 className="text-lg font-semibold mb-2">Expired Subscriptions</h3>
                {expiredSubscriptions.length === 0 ? (
                    <p>No expired subscriptions.</p>
                ) : (
                    expiredSubscriptions.map((sub, index) => (
                        <div key={index} className="mb-2">
                            <p><strong>Producer Name:</strong> {sub.producerName}</p>
                            <p><strong>Producer ID:</strong> {sub.producerId}</p>
                            <p><strong>Cost:</strong> {sub.cost} {sub.currency}</p>
                            <p><strong>Expired:</strong> {sub.expire ? 'Yes' : 'No'}</p>
                            <p><strong>Expire Date:</strong> {new Date(sub.expireDate.seconds * 1000).toLocaleString()}</p>
                            <p><strong>Payment Gateway:</strong> {sub.paymentGateway}</p>
                            <p><strong>Transaction ID:</strong> {sub.transactionID}</p>
                            <p><strong>Subscription Date:</strong> {new Date(sub.timestamp.seconds * 1000).toLocaleString()}</p>
                        </div>
                    ))
                )}
            </div>
            <div>
                <h3 className="text-lg font-semibold mb-2">Active Subscriptions</h3>
                {activeSubscriptions.length === 0 ? (
                    <p>No active subscriptions.</p>
                ) : (
                    activeSubscriptions.map((sub, index) => (
                        <div key={index} className="mb-2">
                            <p><strong>Producer Name:</strong> {sub.producerName}</p>
                            <p><strong>Producer ID:</strong> {sub.producerId}</p>
                            <p><strong>Cost:</strong> {sub.cost} {sub.currency}</p>
                            <p><strong>Expired:</strong> {sub.expire ? 'Yes' : 'No'}</p>
                            <p><strong>Expire Date:</strong> {new Date(sub.expireDate.seconds * 1000).toLocaleString()}</p>
                            <p><strong>Payment Gateway:</strong> {sub.paymentGateway}</p>
                            <p><strong>Transaction ID:</strong> {sub.transactionID}</p>
                            <p><strong>Subscription Date:</strong> {new Date(sub.timestamp.seconds * 1000).toLocaleString()}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'subscriptions'));
                const subs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                const userNames = {};
                const producerNames = {};

                // Fetch user names and producer names
                for (const sub of subs) {
                    if (!userNames[sub.userId]) {
                        const userDoc = await getDoc(doc(db, 'users', sub.userId));
                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            userNames[sub.userId] = {
                                displayName: userData.displayName,
                                email: userData.email || 'No email available',
                                phone: userData.phone || 'No phone available'
                            };
                        } else {
                            userNames[sub.userId] = {
                                displayName: 'Unknown User',
                                email: 'No email available',
                                phone: 'No phone available'
                            };
                        }
                    }
                    if (!producerNames[sub.producerId]) {
                        const producerDoc = await getDoc(doc(db, 'users', sub.producerId));
                        if (producerDoc.exists()) {
                            producerNames[sub.producerId] = producerDoc.data().displayName;
                        } else {
                            producerNames[sub.producerId] = 'Unknown Producer';
                        }
                    }
                }

                const subsWithNames = subs.map(sub => ({
                    ...sub,
                    userName: userNames[sub.userId].displayName,
                    userEmail: userNames[sub.userId].email,
                    userPhone: userNames[sub.userId].phone,
                    producerName: producerNames[sub.producerId]
                }));

                setSubscriptions(subsWithNames);
            } catch (error) {
                toast.error('Error fetching subscriptions: ' + error.message);
            }
        };

        fetchSubscriptions();
    }, []);

    const groupedSubscriptions = subscriptions.reduce((acc, sub) => {
        if (!acc[sub.userId]) {
            acc[sub.userId] = {
                userName: sub.userName,
                userEmail: sub.userEmail,
                userPhone: sub.userPhone,
                expiredSubscriptions: [],
                activeSubscriptions: []
            };
        }
        if (sub.expire) {
            acc[sub.userId].expiredSubscriptions.push(sub);
        } else {
            acc[sub.userId].activeSubscriptions.push(sub);
        }
        return acc;
    }, {});

    const sortedGroupedSubscriptions = Object.values(groupedSubscriptions).map(user => {
        user.expiredSubscriptions.sort((a, b) => b.expireDate.seconds - a.expireDate.seconds);
        user.activeSubscriptions.sort((a, b) => a.expireDate.seconds - b.expireDate.seconds);
        return user;
    }).sort((a, b) => {
        const latestExpiredA = a.expiredSubscriptions[0]?.expireDate.seconds || 0;
        const latestExpiredB = b.expiredSubscriptions[0]?.expireDate.seconds || 0;
        return latestExpiredB - latestExpiredA;
    });

    return (
        <div className="container mx-auto p-6 w-full">
            <header className="mb-6 flex justify-between items-center">
                <h1 className="text-3xl font-bold">Subscriptions</h1>
            </header>
            <div className='flex flex-wrap gap-2'>
                {sortedGroupedSubscriptions.map((user, index) => (
                    <UserSubscription
                        key={index}
                        userName={user.userName}
                        userEmail={user.userEmail}
                        userPhone={user.userPhone}
                        expiredSubscriptions={user.expiredSubscriptions}
                        activeSubscriptions={user.activeSubscriptions}
                    />
                ))}
            </div>
        </div>
    );
};

export default Subscriptions;