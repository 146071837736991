import React, { useState, useEffect } from 'react';
import { collection, query, where, getDoc, getDocs, updateDoc, doc, increment, arrayRemove, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Link } from 'react-router-dom';
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeartCircle } from "react-icons/io5";

const New = () => {
  const userId = auth.currentUser.uid;
  const [newMovies, setNewMovies] = useState([]);

  useEffect(() => {
    const fetchNewMovies = async () => {
      try {
        const currentDate = new Date();
        const currentDay = currentDate.getDay();

        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - (currentDay % 7)); // Adjust for Monday
        startOfWeek.setHours(0, 0, 0, 0); // Set to Monday midnight

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999); // Set to Sunday just before midnight

        const moviesCollection = collection(db, 'movies');
        const q = query(
          moviesCollection,
          where('timeStamp', '>=', startOfWeek),
          where('timeStamp', '<=', endOfWeek)
        );
        const querySnapshot = await getDocs(q);
        const fetchedMovies = [];
        querySnapshot.forEach((doc) => {
          fetchedMovies.push({ id: doc.id, ...doc.data() });
        });
        setNewMovies(fetchedMovies);
      } catch (error) {
        console.error('Error fetching new movies:', error);
      }
    };

    fetchNewMovies();
  }, []);

  const handleLikePost = async (movieId) => {
    try {
      const movieRef = doc(db, 'movies', movieId);
      const movieSnapshot = await getDoc(movieRef);
  
      if (movieSnapshot.exists()) {
        const movieData = movieSnapshot.data();
        const userAlreadyLiked = movieData.likedBy && movieData.likedBy.includes(userId);
  
        if (!userAlreadyLiked) {
          // User hasn't liked the movie yet, increment likes count and add user to likedBy array
          await updateDoc(movieRef, {
            likes: increment(1),
            likedBy: arrayUnion(userId)
          });
          console.log('Movie liked by new user!');
        } else {
          // User already liked the movie, decrement likes count and remove user from likedBy array
          await updateDoc(movieRef, {
            likes: increment(-1),
            likedBy: arrayRemove(userId)
          });
          console.log('Movie unliked by user!');
        }
      }
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-semibold mb-4">New Releases</h2>
      <div className="flex flex-wrap gap-4">
        {newMovies.map((movie) => (
          <div key={movie.id} className='flex flex-col rounded-2xl shadow-xl text-white bg-black h-[392px] w-[280px]'>
            <div className="relative overflow-hidden rounded-2xl">
              <div className='flex absolute inset-0 h-[15%] bg-gradient-to-b from-black from-2%' />
              <img
                src={movie.CoverImage}
                alt=""
                className="w-full h-full object-cover"
              />
              <div className='absolute inset-0 bg-gradient-to-t from-black from-5%' />
              <div className="flex flex-col justify-between absolute inset-0 p-3 rounded-2xl">
                <div className='flex gap-4 justify-between items-center'>
                  <div className='flex gap-2 justify-center items-center'>
                    <img src={movie.ProducerImageUrl} className='rounded-full object-cover h-[30px] w-[30px] bg-zinc-100 hover:bg-zinc-200' />
                    <div>{movie.ProducerName}</div>
                  </div>
                  <div>
                    <button onClick={() => handleLikePost(movie.id)} className="like-button">
                      {movie.likedBy && movie.likedBy.includes(userId) ? (
                        <IoHeartCircle className="liked-icon" />
                      ) : (
                        <IoMdHeartEmpty className="like-icon" />
                      )}
                    </button>
                  </div>
                </div>
                <div className='mt-4 gap-2'>
                  <div>
                    <Link to={`/movies/${movie.Type === 'Single Film' ? 'film' : 'movie'}/${movie.id}`} className='font-bold text-[18px] hover:underline py-1'>{movie.MovieTitle}</Link>
                    <div className='text-[10px] py-1'>{movie.Type} | 1h 47m | {Array.isArray(movie.Genres) ? movie.Genres.join(', ') : movie.Genres} | 2024</div>
                  </div>
                  <div className='text-[12px] py-1'>A master thief is wooed by his ex-girlfriend and the FBI to pull off an impossible heist with his international crew on a 777 passenger flight from London to Zurich.</div>
                  <div className='text-[12px] py-1'>{movie?.likes} Likes</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default New;