import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, getMetadata, StorageError } from 'firebase/storage';
import { doc, getDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db, storage } from "../firebase";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import { DarkModeContext } from '../context/darkModeContext';
import { downloadIcon } from '../assets';
import { MdVerified } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import axios from 'axios';

const Film = () => {
  const { darkMode } = useContext(DarkModeContext);
  const { id } = useParams(); // Access the URL parameter
  const navigate = useNavigate(); // Access the navigate function
  const [movie, setMovie] = useState(null);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    const fetchMovie = async () => {
      try {
        const movieDocRef = doc(db, 'movies', id); // Reference to the specific movie document
        const movieDocSnapshot = await getDoc(movieDocRef);
        if (movieDocSnapshot.exists()) {
          // If the movie document exists, set the movie state
          setMovie(movieDocSnapshot.data());
        } else {
          console.error('Movie not found.');
        }
      } catch (error) {
        console.error('Error fetching movie:', error);
      }
    };

    fetchMovie();
  }, [id]);

  const handleDelete = async () => {
    // Show confirmation alert
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this movie?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const movieDocRef = doc(db, "movies", id);
              const movieDocSnapshot = await getDoc(movieDocRef);

              if (movieDocSnapshot.exists()) {
                // Move the movie document to the Recycle Bin collection
                await setDoc(doc(db, "recycleBin", id), movieDocSnapshot.data());

                // Delete the movie document from the movies collection
                await deleteDoc(movieDocRef);

                // Redirect user to the '/movies' page
                navigate('/movies');

                // Show success message
                toast.success("Movie moved to Recycle Bin successfully!");
              } else {
                toast.error("Movie not found.");
              }
            } catch (err) {
              console.log(err);
              toast.error("Failed to move movie to Recycle Bin.");
            }
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  const download = async () => {
    try {
      // Get the download URL of the movie from Firebase Firestore
      const downloadURL = movie.download;

      // Start the download using Axios
      axios({
        url: downloadURL,
        method: 'GET',
        responseType: 'blob', // Set the response type to blob
      })
        .then((res) => {
          // Create a URL for the Blob object
          const url = window.URL.createObjectURL(new Blob([res.data]));

          // Create a new anchor element to trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${movie.MovieTitle}.mp4`); // Set the filename for download
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.error('Error downloading movie:', err);
          toast.error('Failed to download movie.');
        });
    } catch (error) {
      console.error('Error downloading movie:', error);
      toast.error('Failed to download movie.');
    }
  };

  const startDownload = () => {
    axios({
      url: "movie.download",
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', movie.MovieTitle);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        alert(err);
      })
  };

  return (
    <div>
      {movie ? (
        <div>
          <div>
            <div className={`rounded-2xl shadow-xl h-[200px] flex justify-between p-5 px-10 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white"}`}>
              <div className='flex justify-between gap-5'>
                <img
                  src={movie.CoverImage}
                  alt='Movie Cover Page'
                  title='Movie Cover Page'
                  className='bg-zinc-400 w-[120px] rounded-2xl'
                />
                <div className=''>
                  <div className='font-bold text-[18px] pb-1'>{movie.MovieTitle}</div>
                  <div className='flex gap-1'>
                    <div className='text-[14px] text-slate-500 font-medium'>{movie.movieCountry}</div>
                    <div className='text-[14px] text-slate-600 font-medium'>{movie.Type}</div>
                  </div>
                  {movie.Genres.length > 0 ? (
                    <>
                      {movie.Genres.map((genre, index) => (
                        <div key={index} className='text-[14px]'>{genre}</div>
                      ))}
                    </>
                  ) : (
                    <div className='text-[14px]'>Genre</div>
                  )}
                  <div className='text-[14px]'>Duration: {movie.Duration}</div>
                </div>
              </div>
              <div className='flex justify-between gap-8'>
                <div>
                  {movie.Trailer ? (
                    <>
                      <ReactPlayer
                        light={true}
                        controls={true}
                        url={movie?.Trailer}
                        height="140px"
                        width="260px"
                        className='border-2'
                      />
                      <div className='text-[12px] text-slate-400 font-semibold text-center'>Trailer</div>
                    </>
                  ) : (null)}
                </div>
                <div className='w-[2px] bg-gray-200 h-full'></div>
                <div className='items-center flex flex-col flex-shrink-0'>
                  <img
                    src={movie.ProducerImageUrl || 'https://firebasestorage.googleapis.com/v0/b/trood-app-c5eae.appspot.com/o/1712773008995iTrood.png?alt=media&token=34f6279d-1425-4016-a57b-83a12115c27d'}
                    alt='Producer Image'
                    title='Producer Image'
                    className='h-[80px] w-[80px] m-2 shrink-0 rounded-full bg-zinc-100'
                  />
                  <div className='pt-2'>
                    <div className='gap-1 flex justify-center items-center'>
                      <div className='text-[13px] font-semibold text-center'>{movie.ProducerName || "DJ XXX"}</div>
                      {movie?.ProducerVerificationStatus === "verified" && (
                        <div className='text-[13px] font-semibold text-center'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
                      )}
                    </div>
                    <div className='text-[12px] font-semibold text-center text-gray-300'>Producer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='m-2 flex flex-col items-center justify-center'>
            <h2 className='ml-2 font-bold text-slate-400'>Download</h2>
            <p>{movie?.Description}</p>
            <div className='m-2'>
              <div className={`m-2 flex p-2 justify-between items-center w-[315px] h-[80px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin resize-none shadow-md ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode"}`}>
                <div className='flex gap-1 items-center'>
                  <img src={movie.CoverImage} className='h-[50px] w-[35px] m-2 shrink-0 rounded-md bg-zinc-200' />
                  <div>
                    <p className='text-[14px] font-semibold'>{movie.MovieTitle}</p>
                    <p className='text-[12px]'>{movie.Duration}</p>
                  </div>
                </div>
                <a href={movie.DownloadLink} target='_blank' className='flex flex-col gap-1'>
                  <div className='h-[40px] w-[40px] justify-center items-center flex bg-zinc-100 hover:bg-zinc-200 rounded-full border'>
                    <img src={downloadIcon} className='h-[20px] object-contain' />
                  </div>
                  <p className='text-[10px] font-semibold'>Download</p>
                </a>
              </div>
              {progress !== null && (
                <progress value={progress} max="100" className="w-full mt-4"></progress>
              )}
              {/* {progress ? (
                <div className='text-[14px] text-center p-2 m-2 text-blue-500 font-semibold'>Downloading {progress}%</div>
              ) : (
                <div onClick={startDownload} className='text-[14px] text-center p-2 m-2 text-blue-500 font-semibold cursor-pointer'>Fast Download</div>
              )} */}
            </div>
          </div>
          <div className='flex w-full h-[100px] justify-center items-center'>
            <button onClick={handleDelete} className='text-center text-red-500 cursor-pointer border-spacing-4 rounded-xl border-red-500 hover:bg-red-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>Delete</button>
          </div>
        </div>
      ) : (
        // Loading skelleton
        <div>
          Loading
        </div>
      )}
    </div>
  );
};

export default Film;