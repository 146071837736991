import React, { useState, useEffect, useContext } from 'react';
import { collection, doc, addDoc, setDoc, getDoc, getDocs, query, where, orderBy, serverTimestamp } from "firebase/firestore";
import { db } from '../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from '../context/darkModeContext';
import { icon } from '../assets';

const CreatePost = () => {
  const navigate = useNavigate();
  const { darkMode } = useContext(DarkModeContext);
  const [seasons, setSeasons] = useState([]);
  const [filteredSeasons, setFilteredSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState('');
  const [seasonData, setSeasonData] = useState(null);
  const [newEpisodes, setNewEpisodes] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [episodesCount, setEpisodesCount] = useState(0);
  const [lastEpisodeTitle, setLastEpisodeTitle] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSeasons = async () => {
      const seasonQuery = query(collection(db, 'movies'), where('Type', '==', 'Season'));
      const seasonSnapshot = await getDocs(seasonQuery);
      const seasonList = seasonSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSeasons(seasonList);
      setFilteredSeasons(seasonList);
    };

    fetchSeasons();
  }, []);

  useEffect(() => {
    const filteredSeasons = seasons.filter(season => season.MovieTitle.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredSeasons(filteredSeasons);
  }, [searchTerm, seasons]);

  useEffect(() => {
    const fetchSeasonData = async () => {
      if (selectedSeason) {
        const seasonDocRef = doc(db, 'movies', selectedSeason);
        const seasonSnapshot = await getDoc(seasonDocRef);
        if (seasonSnapshot.exists()) {
          setSeasonData(seasonSnapshot.data());
          const episodesQuery = query(collection(db, 'movies', selectedSeason, 'Episodes'), orderBy('title', 'asc'));
          const episodesSnapshot = await getDocs(episodesQuery);
          const episodesData = episodesSnapshot.docs.map(doc => doc.data());
          setEpisodesCount(episodesData.length);
          if (episodesData.length > 0) {
            setLastEpisodeTitle(episodesData[episodesData.length - 1].title);
          }
        }
      }
    };

    fetchSeasonData();
  }, [selectedSeason]);

  const handleSelectSeason = (e) => {
    setSelectedSeason(e.target.value);
  };

  const handleAddNewEpisode = () => {
    setNewEpisodes([...newEpisodes, { title: '', downloadLink: '' }]);
  };

  const handleRemoveEpisode = (indexToRemove) => {
    setNewEpisodes(newEpisodes.filter((_, index) => index !== indexToRemove));
  };

  const handleEpisodeChange = (index, key, value) => {
    const updatedEpisodes = [...newEpisodes];
    updatedEpisodes[index][key] = value;
    setNewEpisodes(updatedEpisodes);
  };

  useEffect(() => {
    if (selectedSeason && newEpisodes.length > 0 && newEpisodes.every(episode => episode.title && episode.downloadLink)) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedSeason, newEpisodes]);

  const handleSubmit = async () => {
    try {
      // Fetch movie details
      let ProducerID = '';
      let ProducerName = '';
      let ProducerImageUrl = '';
      let MovieTitle = '';
      let CoverImage = ''; // New field for the movie cover image
      if (selectedSeason) {
        const seasonDocRef = doc(db, 'movies', selectedSeason);
        const seasonSnapshot = await getDoc(seasonDocRef);
        if (seasonSnapshot.exists()) {
          const seasonData = seasonSnapshot.data();
          ProducerID = seasonData.ProducerID || '';
          ProducerName = seasonData.ProducerName || '';
          ProducerImageUrl = seasonData.ProducerImageUrl || '';
          MovieTitle = seasonData.MovieTitle || '';
          CoverImage = seasonData.CoverImage || ''; // Get the cover image URL
        }
      }
  
      // Create a new post document
      const postRef = await addDoc(collection(db, 'posts'), {
        season: selectedSeason,
        episodes: newEpisodes,
        content: postContent,
        timestamp: serverTimestamp(),
        // Include additional information for the post
        ProducerID: ProducerID,
        ProducerName: ProducerName,
        ProducerImageUrl: ProducerImageUrl,
        MovieTitle: MovieTitle,
        CoverImage: CoverImage, // Include the cover image URL in the post
      });
      console.log('Post created with ID: ', postRef.id);
  
      // Update the 'movies' collection with new episode data
      if (selectedSeason) {
        const seasonDocRef = doc(db, 'movies', selectedSeason);
        const seasonSnapshot = await getDoc(seasonDocRef);
        if (seasonSnapshot.exists()) {
          const seasonData = seasonSnapshot.data();
          const updatedEpisodes = [...seasonData.Episodes, ...newEpisodes];
          await setDoc(seasonDocRef, { Episodes: updatedEpisodes }, { merge: true });
          console.log('Episodes added to the season: ', selectedSeason);
        }
      }
  
      toast.success('Weekly movie post created successfully');
  
      // Navigate back to '/create' after a successful movie release
      navigate('/create');
  
      // Reset form fields
      setSelectedSeason('');
      setNewEpisodes([]);
      setPostContent('');
  
      // Show success message or redirect to another page
    } catch (error) {
      console.error('Error creating post: ', error);
      // Show error message
    }
  };

  return (
    <div>
      <h2>Create Post</h2>
      <div>
        {/* Dropdown to select the season */}
        <input
          type="text"
          placeholder="Search Seasons"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select value={selectedSeason} onChange={handleSelectSeason}>
          <option value="">Select Season</option>
          {filteredSeasons.map(season => (
            <option key={season.id} value={season.id}>{season.MovieTitle}</option>
          ))}
        </select>
      </div>
      {seasonData && (
        <div>
          <h3>{seasonData.MovieTitle}</h3>
          <div className={`h-[180px] w-[120px] hover:ml-[12px] shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-white"}`}>
            <img src={seasonData.CoverImage} alt='Movie Cover' className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
          </div>
          {/* <p>Date: {seasonData.Date.toDate().toString()}</p> */}
          {/* <p>Download Link: <a href={seasonData.DownloadLink} target="_blank" rel="noopener noreferrer">{seasonData.DownloadLink}</a></p> */}
          <p>Genres: {seasonData.Genres.join(', ')}</p>
          {/* Display other season details */}
          <div>
            <h4>Episodes</h4>
            {/* <ul>
              {seasonData.Episodes.map((episode, index) => (
                <li key={index}>
                  {episode.title} - <a href={episode.downloadLink} target="_blank" rel="noopener noreferrer">Download</a>
                </li>
              ))}
            </ul> */}
            <p>Total Episodes: {seasonData.Episodes.length}</p>
            <p>Last Episode: {seasonData.Episodes[seasonData.Episodes.length - 1]?.title}</p>
          </div>
        </div>
      )}
      <div>
        <h4>New Episodes</h4>
        {newEpisodes.map((episode, index) => (
          <div key={index}>
            <input
              type="text"
              placeholder="Episode Title"
              value={episode.title}
              onChange={(e) => handleEpisodeChange(index, 'title', e.target.value)}
            />
            <input
              type="text"
              placeholder="Download Link"
              value={episode.downloadLink}
              onChange={(e) => handleEpisodeChange(index, 'downloadLink', e.target.value)}
            />
            <button onClick={() => handleRemoveEpisode(index)}>Remove</button>
          </div>
        ))}
        <button onClick={handleAddNewEpisode}>Add Episode</button>
      </div>
      <div>
        <h4>Post Content</h4>
        <textarea
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          rows={5}
          cols={50}
        />
      </div>
      <button onClick={handleSubmit} disabled={submitDisabled}>Submit</button>
    </div>
  );
};

export default CreatePost;