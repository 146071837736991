import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { logo2 } from '../assets';

const Create = () => {
  const create = [
    {
      title: 'Create a post',
      description: 'Weekly posts',
      route: '/create/post'
    },
    {
      title: 'Create a new release',
      description: 'For new films and movies',
      route: '/create/release'
    },
    {
      title: 'Create User',
      description: 'New user account',
      route: '/create/user'
    },
    {
      title: 'Create codes',
      description: 'Pre-Paid Codes (Vouchers), Promo Codes, Offers.',
      route: '/create/codes'
    },
  ];

  return (
    <div className='flex flex-col w-full h-full items-center justify-center'>
      <div className='m-20 mt-[100px] flex-shrink-0'>
        <img src={logo2} className='w-[250px] shrink-0' />
      </div>
      <div className='mb-20'>
        <div className='flex flex-wrap gap-2 m-4 justify-center items-center'>
          {create.map((item, index) => (
            <Link
              to={`${item.route}`}
              key={index}
              className='flex flex-col border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)] justify-center px-2 my-1 w-[280px] h-20 items-start rounded-lg mx-1 cursor-pointer'
            >
              <p className='font-bold text-[14px]'>{item.title}</p>
              <p className='text-[12px]'>{item.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Create;