import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db } from "../firebase";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { downloadIcon } from '../assets';
import ReactPlayer from 'react-player';
import { DarkModeContext } from '../context/darkModeContext';

const Movie = () => {
  const { darkMode } = useContext(DarkModeContext);
  const { id } = useParams(); // Access the URL parameter
  const navigate = useNavigate(); // Access the navigate function
  const [movie, setMovie] = useState(null);

  useEffect(() => {
    const fetchMovie = async () => {
      try {
        const movieDocRef = doc(db, 'movies', id); // Reference to the specific movie document
        const movieDocSnapshot = await getDoc(movieDocRef);
        if (movieDocSnapshot.exists()) {
          // If the movie document exists, set the movie state
          setMovie(movieDocSnapshot.data());
        } else {
          console.error('Movie not found.');
        }
      } catch (error) {
        console.error('Error fetching movie:', error);
      }
    };

    fetchMovie();
  }, [id]);

  const handleDelete = async () => {
    // Show confirmation alert
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this movie?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const movieDocRef = doc(db, "movies", id);
              const movieDocSnapshot = await getDoc(movieDocRef);

              if (movieDocSnapshot.exists()) {
                // Move the movie document to the Recycle Bin collection
                await setDoc(doc(db, "recycleBin", id), movieDocSnapshot.data());

                // Delete the movie document from the movies collection
                await deleteDoc(movieDocRef);

                // Redirect user to the '/movies' page
                navigate('/movies');

                // Show success message
                toast.success("Movie moved to Recycle Bin successfully!");
              } else {
                toast.error("Movie not found.");
              }
            } catch (err) {
              console.log(err);
              toast.error("Failed to move movie to Recycle Bin.");
            }
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  return (
    <div>
      {movie ? (
        <div>
          <div>
            <div className={`rounded-2xl shadow-xl h-[200px] flex justify-between p-5 px-10 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white"}`}>
              <div className='flex justify-between gap-5'>
                <img
                  src={movie.CoverImage}
                  alt='Movie Cover Page'
                  title='Movie Cover Page'
                  className='bg-zinc-400 w-[120px] rounded-2xl'
                />
                <div className=''>
                  <div className='font-bold text-[18px] pb-1'>{movie.MovieTitle}</div>
                  <div className='flex gap-1'>
                    <div className='text-[14px] text-slate-500 font-medium'>{movie.movieCountry}</div>
                    <div className='text-[14px] text-slate-600 font-medium'>{movie.Type}</div>
                  </div>
                  {/* {movieType === 'Season' && (
                    <div className='text-[14px]'>Episodes: {episodes.length}</div>
                  )} */}
                  {movie.Genres.length > 0 ? (
                    <>
                      {movie.Genres.map((genre, index) => (
                        <div key={index} className='text-[14px]'>{genre}</div>
                      ))}
                    </>
                  ) : (
                    <div className='text-[14px]'>Genre</div>
                  )}
                  <div className='text-[14px]'>Finish: {movie.Finish}</div>
                </div>
              </div>
              <div className='flex justify-between gap-8'>
                <div>
                  {movie.Trailer ? (
                    <>
                      <ReactPlayer
                        light={true}
                        controls={true}
                        url={movie?.Trailer}
                        height="140px"
                        width="260px"
                        className='border-2'
                      />
                      <div className='text-[12px] text-slate-400 font-semibold text-center'>Trailer</div>
                    </>
                  ) : (null)}
                </div>
                <div className='w-[2px] bg-gray-200 h-full'></div>
                <div className='items-center flex flex-col flex-shrink-0'>
                  <img
                    src={movie.ProducerImageUrl || 'https://firebasestorage.googleapis.com/v0/b/trood-app-c5eae.appspot.com/o/1712773008995iTrood.png?alt=media&token=34f6279d-1425-4016-a57b-83a12115c27d'}
                    alt='Producer Image'
                    title='Producer Image'
                    className='h-[80px] w-[80px] m-2 shrink-0 rounded-full bg-zinc-100'
                  />
                  <div className='pt-2'>
                    <div className='text-[13px] font-semibold text-center'>{movie.ProducerName || "DJ XXX"}</div>
                    <div className='text-[12px] font-semibold text-center text-gray-300'>Producer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='m-2'>
            <p>{movie?.Description}</p>
            <h2 className='ml-2 font-bold text-slate-400'>Episodes</h2>
            <ul className='flex flex-wrap justify-start'>
              {movie?.Episodes.map((episode, index) => (
                <li key={index} className={`m-2 flex p-2 justify-between items-center w-[315px] h-[80px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin resize-none shadow-md ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode"}`}>
                  <div className='flex gap-1 items-center'>
                    <img src={movie.CoverImage} className='h-[50px] w-[35px] m-2 shrink-0 rounded-md bg-zinc-200' />
                    <div>
                      <p className='text-[14px] font-semibold'>{movie.MovieTitle}</p>
                      <p className='text-[12px]'>{episode.title}</p>
                    </div>
                  </div>
                  <a href={episode.downloadLink} target='_blank' className='flex flex-col gap-1'>
                    <div className='h-[40px] w-[40px] justify-center items-center flex bg-zinc-100 hover:bg-zinc-200 rounded-full border'>
                      <img src={downloadIcon} className='h-[20px] object-contain' />
                    </div>
                    <p className='text-[10px] font-semibold'>Download</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={handleDelete} className='text-red-500 mt-10'>Delete</button>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Movie;