import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Chart from "../code/Chart";
import UserTransactions from "../code/UserTransactions";
import { DarkModeContext } from '../context/darkModeContext';

const User = () => {
  const { id } = useParams();
  const { darkMode } = useContext(DarkModeContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDocRef = doc(db, "users", id);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setUser(userDocSnapshot.data());
        } else {
          setError("User not found");
        }
      } catch (err) {
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={`flex ${darkMode ? "dark-mode-class" : "light-mode-class"}`}>
      <div className="flex-1 p-4">
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="border border-gray-200 p-4 relative">
              <div className="absolute top-0 right-0 p-2 text-xs text-purple-600 bg-purple-100 rounded-bl-md cursor-pointer">Edit</div>
              <h1 className="text-lg font-semibold text-gray-700">Information</h1>
              <div className="flex gap-4 mt-4">
                <div>
                  <img
                    src={user?.img || "https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"}
                    alt=""
                    className="w-24 h-24 rounded-full object-cover"
                  />
                </div>
                <div>
                  <h1 className="text-xl font-semibold text-gray-700">{user?.displayName || "User"}</h1>
                  <div className="mt-2">
                    <div className="flex items-center mb-1">
                      <span className="font-semibold text-gray-600 mr-2">Email:</span>
                      <span>{user?.email || "email"}</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <span className="font-semibold text-gray-600 mr-2">Phone:</span>
                      <span>{user?.phone || "phone"}</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <span className="font-semibold text-gray-600 mr-2">Address:</span>
                      <span>{user?.address || "address"}</span>
                    </div>
                    <div className="flex items-center">
                      <span className="font-semibold text-gray-600 mr-2">Country:</span>
                      <span>{user?.country || "country"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 p-4">
              <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
            </div>
          </div>
          <div className="border border-gray-200 p-4 mt-4">
            <h1 className="text-lg font-semibold text-gray-700 mb-4">Last Transactions</h1>
            <UserTransactions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;