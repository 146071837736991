import React from 'react'
import Datatable from "../code/Datatable";

const Users = () => {
  return (
    <div className="flex list h-full">
      <div className="flex flex-col flex-grow">
        <div className="flex-grow mb-5">
          <Datatable />
        </div>
      </div>
    </div>
  );
};

export default Users;
