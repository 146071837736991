import React from 'react'
import Widget from "../code/Widget";
import Featured from "../code/Featured";
import Chart from "../code/Chart";
import Table from "../code/Table";

const Dashboard = () => {
  return (
    <div className="flex home">
      <div className="flex flex-col flex-grow">
        <div className="flex flex-col justify-between px-4 py-6">
          <div className="flex gap-4 pb-10">
            <Widget type="user" />
            <Widget type="movie" />
            {/* <Widget type="singleFilm" /> */}
            {/* <Widget type="season" /> */}
            {/* <Widget type="earning" /> */}
          </div>
          <div className="flex flex-row max-md:flex-col gap-6">
            <Featured />
            <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
          </div>
        </div>
        <div className="mx-4 mt-8">
          <div className="text-lg font-semibold text-gray-500 mb-4">
            Latest Transactions
          </div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
