import React, { useContext } from 'react';
import { DarkModeContext } from '../context/darkModeContext';

const TransactionTable = ({ people }) => {
  const { darkMode } = useContext(DarkModeContext);
  
  return (
    <table className='w-[100%] mx-auto shadow-md mb-4'>
      <thead>
        <tr>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-gray-300 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>Tracking ID</td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-gray-300 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>Action</td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-gray-300 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>Customer</td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-gray-300 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>Date</td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-gray-300 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>Amount</td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-gray-300 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>Payment Method</td>
          <td className={`p-2 ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-gray-300 "}`} style={{ fontWeight: 'bold', paddingLeft: 20 }}>Status</td>
        </tr>
      </thead>
      <tbody>
        {people.map((person, index) => (
          <tr key={person.id} className={index % 2 === 0 ? `${darkMode ? "border text-white" : "light-mode bg-gray-100 "}` : `${darkMode ? "bg-[#17222978] text-white" : "light-mode bg-gray-200"}` }>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>{person.id}</td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>{person.action}</td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>{person.customer}</td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>{person.date}</td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>{person.amount}</td>
            <td className={`p-2 ${darkMode ? "text-white" : "light-mode"}`} style={{ paddingLeft: 20 }}>{person.method}</td>
            <td className={`p-2 status px-2 py-1 ${person.status === 'Approved' ? `${darkMode ? "text-white" : "light-mode text-green-500 bg-green-100"}` : `${darkMode ? "text-white" : "light-mode text-yellow-500 bg-yellow-100"}`}`} style={{ paddingLeft: 20 }}>{person.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function App() {
    const people = [
        {
          id: 1143155,
          action: "Withdraw",
          customer: "John Smith",
          date: "1 March",
          amount: 785,
          method: "Cash on Delivery",
          status: "Approved",
        },
        {
          id: 2235235,
          action: "Withdraw",
          customer: "Michael Doe",
          date: "1 March",
          amount: 900,
          method: "Online Payment",
          status: "Pending",
        },
        {
          id: 2342353,
          action: "Withdraw",
          customer: "John Smith",
          date: "1 March",
          amount: 35,
          method: "Cash on Delivery",
          status: "Pending",
        },
        {
          id: 2357741,
          action: "Withdraw",
          customer: "Jane Smith",
          date: "1 March",
          amount: 920,
          method: "Online",
          status: "Approved",
        },
        {
          id: 2342355,
          action: "Withdraw",
          customer: "Harold Carol",
          date: "1 March",
          amount: 2000,
          method: "Online",
          status: "Pending",
        },
      ];

  return (
    <div className="App p-1 flex justify-center">
      <TransactionTable people={people} />
    </div>
  );
}

export default App;