import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

const Posts = () => {
  const [newPosts, setNewPosts] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const fetchNewPosts = async () => {
      try {
        console.log("Fetching new posts...");
        // Calculate the start and end dates for the current week
        const startOfWeek = new Date(currentDate);
        startOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999); // Set to the end of the day

        // Convert start and end of the week to Firestore Timestamps
        const startOfWeekTimestamp = Timestamp.fromDate(startOfWeek);
        const endOfWeekTimestamp = Timestamp.fromDate(endOfWeek);

        // Fetch posts made during the current week (starting from Monday)
        const postsCollection = collection(db, 'posts');
        const q = query(
          postsCollection,
          where('timestamp', '>=', startOfWeekTimestamp),
          where('timestamp', '<=', endOfWeekTimestamp)
        );
        const querySnapshot = await getDocs(q);
        const fetchedPosts = [];
        querySnapshot.forEach((doc) => {
          fetchedPosts.push(doc.data());
        });
        console.log("Fetched posts:", fetchedPosts);
        setNewPosts(fetchedPosts);
      } catch (error) {
        console.error('Error fetching new posts:', error);
      }
    };

    fetchNewPosts();
  }, [currentDate]);

  const getCurrentDayName = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[currentDate.getDay()];
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className='flex gap-5 justify-between'>
        <h2 className="text-3xl font-semibold mb-4">New Posts</h2>
        <h2 className="text-3xl font-semibold mb-4 flex gap-2">
          <div>Date: {currentDate.toLocaleDateString()}</div>
          <div>|</div>
          <div className={`text-${getCurrentDayName() === 'Sunday' ? 'red' : 'blue'}-500`}>{getCurrentDayName()}</div>
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <div>
            {newPosts.map((post) => (
              <div className='flex gap-2' key={post.id}>
                <Link to={`/movies/movie/${post.season}`}>
                  <img src={post.CoverImage} alt='iTrood Movie Cover' className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                </Link>
                <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">{post.content}</h3>
                    <p className="text-gray-600 mb-2">Posted by: {post.ProducerName}</p>
                    <img src={post.ProducerImageUrl} alt={post.ProducerName} className="w-16 h-16 rounded-full mb-2 float-right" />
                    <p className="text-gray-600 mb-2">Movie: {post.MovieTitle}</p>
                    <p className="text-gray-600 mb-2">Download <Link to={`/movies/movie/${post.season}`} className="text-blue-500 hover:underline">New Episodes</Link></p>
                    <p className="text-gray-600 mt-2">Number of new episodes released this week: {post.episodes.length}</p>
                    <p className="text-gray-600 mt-2">Episodes Range: {post.episodes[0].title} - {post.episodes[post.episodes.length - 1].title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div></div> {/* Empty div to adjust layout for producer info on the right */}
      </div>
    </div>
  );
};

export default Posts;