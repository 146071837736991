import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Link } from "react-router-dom";

const Widget = ({ type }) => {
  const [amount, setAmount] = useState(null);
  const [diff, setDiff] = useState(null);
  let data;

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "See all users",
        route: `/users`,
        query:"users",
        icon: (
          <PersonOutlinedIcon
            className="text-[18px] p-[5px] rounded self-end"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "movies":
      data = {
        title: "MOVIES",
        isMoney: false,
        link: "See all movies",
        route: `/movies`,
        query:"users",
        icon: (
          <PersonOutlinedIcon
            className="text-[18px] p-[5px] rounded self-end"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "ORDERS",
        isMoney: false,
        link: "View all orders",
        route: `/orders`,
        icon: (
          <ShoppingCartOutlinedIcon
            className="text-[18px] p-[5px] rounded self-end"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "EARNINGS",
        isMoney: true,
        link: "View net earnings",
        route: `/earinings`,
        icon: (
          <MonetizationOnOutlinedIcon
            className="text-[18px] p-[5px] rounded self-end"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "movie":
      data = {
        title: "MOVIES",
        query:"movies",
        link: "See details",
        route: `/movies`,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="text-[18px] p-[5px] rounded self-end"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      const today = new Date();
      const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
      const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2));

      const lastMonthQuery = query(
        collection(db, data.query),
        where("timeStamp", "<=", today),
        where("timeStamp", ">", lastMonth)
      );
      const prevMonthQuery = query(
        collection(db, data.query),
        where("timeStamp", "<=", lastMonth),
        where("timeStamp", ">", prevMonth)
      );

      const lastMonthData = await getDocs(lastMonthQuery);
      const prevMonthData = await getDocs(prevMonthQuery);

      setAmount(lastMonthData.docs.length);
      setDiff(
        ((lastMonthData.docs.length - prevMonthData.docs.length) / prevMonthData.docs.length) *
          100
      );
    };
    fetchData();
  }, []);

  return (
    <div className="flex justify-between, flex-1, p-[10px] rounded-xl shadow-xl h-[105px]">
      <div className="flex flex-col h-full w-[200px] justify-between">
        <div className="flex gap-5 justify-between">
          <span className="text-bold font-[14px] text-[#bababa]">{data?.title}</span>
          <div className={`flex items-center text-[14px] ${diff < 0 ? "text-green-500" : "text-red-500"}`}>
            {diff < 0 ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/> }
            {diff} %
          </div>
        </div>
        <span className="text-[28px] font-bold">
          {data?.isMoney && "$"} {amount}
        </span>
        <Link to={data?.route} className='flex flex-row justify-between items-center'>
          <span className="max-w-fit text-[12px] border-b-zinc-500 cursor-pointer underline">{data?.link}</span>
          <div>
            {data?.icon}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Widget;
