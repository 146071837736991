import React from 'react'

const Admin = () => {
  return (
    <div>
      Admin
    </div>
  )
}

export default Admin
